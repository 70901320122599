import React from 'react';
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from "react-router-dom";

import { router } from './routes/AppRoutes';
// import { AuthProvider } from './context/Auth.jsx';
import Loader from '/src/components/common/Loader';

import './styles/styles.scss';
// import './index.css'


/// ***************************************************************************
/// REFRESCA LA CACHE DE PWA cada intervalMS
/// ***************************************************************************
import { registerSW } from 'virtual:pwa-register'

const intervalMS = 1000 * 60 * 60 * 1  // <= cada hora -- msec * sec * min * h

const updateSW = registerSW({
  onRegisteredSW(swUrl, r) {
    r && setInterval(async () => {
      if (r.installing || !navigator)
        return

      if (('connection' in navigator) && !navigator.onLine)
        return

      const resp = await fetch(swUrl, {
        cache: 'no-store',
        headers: {
          'cache': 'no-store',
          'cache-control': 'no-cache',
        },
      })

      if (resp?.status === 200)
        await r.update()
    }, intervalMS)
  }
})
/// ***************************************************************************
/// ***************************************************************************  


updateSW; /* execute auto update service worker */

/// ***************************************************************************
/// 
/// ***************************************************************************
ReactDOM.createRoot(document.getElementById('root')).render(
  
  <React.StrictMode>
  <>
    {console.log('*****-> App -- ENVIRONMENT --', import.meta.env.MODE)}

      {/* <AuthProvider> */}

        {/* <Suspense fallback="loading..."> */}
        <Suspense fallback={ <Loader/> }>
          <RouterProvider router={router} />
          {/* <App /> */} 
        </Suspense> 
        
      {/* </AuthProvider> */}
  </>
  </React.StrictMode>, 
)
